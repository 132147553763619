import 'application.sass'
import Rails from '@rails/ujs'

import 'utils/drawer'
import 'utils/accordion'
import 'utils/integrationsSearch'
import 'utils/cookieConsent'
import 'utils/headerNav'
import 'utils/appHeight'

import 'marketing/email-validation'
import 'marketing/flash'
import 'marketing/password-toggle'
import 'marketing/recaptcha-form'
import 'marketing/plans'

import { initIntervalToggle } from 'utils/intervalToggle'

Rails.start()

window.addEventListener('load', () => {
  // Collapsible
  const items = document.querySelectorAll('.collapsable > h5')
  items.forEach(item => {
    item.addEventListener('click', ({ currentTarget }) =>
      currentTarget.parentNode.classList.toggle('collapsed')
    )
  })

  document
    .querySelectorAll('.interval-toggle-container')
    .forEach(container => initIntervalToggle(container))
})
